import Cookies from './helpers/cookies';
import BrowserInteractionTime from 'browser-interaction-time';

export default class Heya {
  #eventScopes = {}
  #enabled = false

  setEnabled(enabled) {
    this.#enabled = enabled;
  }

  isEnabled() {
    return this.#enabled;
  }

  setEventScope(name, id) {
    this.#eventScopes[name] = id;
  }

  getEventScopes() {
    return this.#eventScopes;
  }

  getEventScope(name) {
    return this.#eventScopes[name];
  }

  getVisitToken() {
    return Cookies.get('ahoy_visit')
  }

  getVisitorToken() {
    return Cookies.get('ahoy_visitor')
  }

  getCrsfToken() {
    const meta = document.querySelector("meta[name=csrf-token]");
    return meta && meta.content;
  }

  generateId() {
    if (window.crypto && window.crypto.randomUUID) {
      return window.crypto.randomUUID();
    }

    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = Math.random() * 16 | 0;
      const v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  toggleDebug() {
    if (Cookies.get('ahoy_debug')) {
      Cookies.destroy('ahoy_debug');
      console.log('Debugging disabled');
    } else {
      Cookies.set('ahoy_debug', 't', 365 * 24 * 60); // 1 year
      console.log('Debugging enabled');
    }
  };

  log(message) {
    if (!Cookies.get('ahoy_debug')) return;

    console.log(message);
  }

  reset() {
    Cookies.destroy('ahoy_visit');
    Cookies.destroy('ahoy_visitor');
    Cookies.destroy('ahoy_events');
    return true;
  };

  sendRequest(url, additionalData, keepAlive = false) {
    if (!this.isEnabled()) return;

    let data = Object.assign(
      {
        time: (new Date()).getTime() / 1000.0,
        visit_token: this.getVisitToken(),
        visitor_token: this.getVisitorToken(),
        platform: 'Web'
      },
      additionalData
    );

    this.log(data)

    fetch(url, {
      keepalive: keepAlive,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.getCrsfToken()
      },
      body: JSON.stringify(data)
    });
  }

  sendCollect(data = {}) {
    if (this.getVisitToken() == null) {
      this.log('Visit token not found');
      return;
    }

    this.sendRequest('/heya/collect', data, true);
  }

  trackEvent(eventName, additionalData = {}, eventId = this.generateId()) {
    let data = Object.assign(
      {
        id: eventId,
        name: eventName,
        landing_page: window.location.href,
        screen_width: window.screen.width,
        screen_height: window.screen.height,
        referrer: document.referrer || null,
      },
      additionalData
    );

    this.sendRequest('/heya/events', data);

    return eventId;
  }

  trackPageView(properties = {}, eventId) {
    let data = {
      properties: Object.assign(
        {
          url: window.location.href,
          title: document.title,
          page: window.location.pathname,
        },
        properties
      )
    }

    this.trackEvent('pageview', data, eventId);
    this.setEventScope('pageview', eventId)
    this.startDurationTracking(eventId);
  }

  startDurationTracking(eventId) {
    const browserTime = new BrowserInteractionTime({
      idleTimeoutMs: 30000
    })

    this.log(`tracking with ID: ${eventId}`)

    const sendDurationUpdate = (timeInMilliseconds) => {
      this.log(`sending duration update. id: ${eventId}`)

      this.sendCollect({ d: timeInMilliseconds, id: eventId });
    }

    browserTime.addBrowserTabInactiveCallback(sendDurationUpdate)
    browserTime.startTimer()

    const periodicUpdate = setInterval(() => {
      if (!browserTime.isRunning()) return;

      this.log(`periodic duration update. id: ${eventId}`)

      sendDurationUpdate(browserTime.getTimeInMilliseconds())
    }, 10000);

    document.addEventListener('turbo:before-visit', () => {
      clearInterval(periodicUpdate)
      sendDurationUpdate(browserTime.getTimeInMilliseconds())
      browserTime.destroy()
    }, { once: true })
  }

  executeInitialEvent() {
    const initialEvent = document.querySelector('meta[name="heya"]')

    if (!initialEvent) return;

    const eventName = initialEvent.dataset.event
    const eventId = initialEvent.dataset.scope;
    const properties = JSON.parse(initialEvent.dataset.properties)

    if (eventName === 'pageview') {
      this.trackPageView(properties, eventId);
    } else {
      this.trackEvent(eventName, { properties: properties }, eventId);
    }

    // initialEvent.remove();
  }

  emitEvent(type) {
    const event = new Event(`heya:${type}`);
    document.dispatchEvent(event);
  }

  appendScopeToUrl(url, scopeName) {
    return url + (url.match(/[\?]/g) ? '&' : '?') + `heya=${this.getEventScope(scopeName)}`;
  }

  setupListeners() {
    if (!this.isEnabled()) return;

    document.querySelectorAll('[data-heya-scope]').forEach((element) => {
      const scopeName = element.dataset.heyaScope;

      switch (element.nodeName) {
        case 'FORM':
          element.addEventListener('submit', () => {
            element.action = this.appendScopeToUrl(element.action, scopeName)
          })
          break;
        case 'A':
          element.addEventListener('click', () => {
            element.href = this.appendScopeToUrl(element.href, scopeName)
          })
          break;
      }
    })
  }

  start() {
    if (!this.isEnabled()) return;

    this.log('Heya starting')
    this.executeInitialEvent();
    this.setupListeners();
    this.emitEvent('load');
  }

  restart() {
    if (!this.isEnabled()) return;

    this.log('Heya restarting')
    this.executeInitialEvent();
    this.setupListeners();
    this.emitEvent('load');
  }

  disabled() {
    this.log('Heya disabled')
    this.emitEvent('load');
  }

  initialize() {
    if (navigator.cookieEnabled) {
      this.setEnabled(true);

      if (document.visibilityState === 'visible') {
        this.start();
      } else {
        document.addEventListener('visibilitychange', () => {
          if (document.visibilityState === 'visible') { this.start(); }
        }, { once: true });
      }
    } else {
      this.emitEvent('load');
    }
  }
}

document.addEventListener('turbo:load', function () {
  if (typeof window.Heya == 'undefined') {
    window.Heya = new Heya;
    window.Heya.initialize();
  } else if (window.Heya.isEnabled()) {
    window.Heya.restart()
  } else {
    window.Heya.disabled()
  }
});

document.addEventListener('turbo:after-stream-render', (event) => {
  if (typeof window.Heya == 'undefined') return;

  window.Heya.setupListeners();
});